/* You can add global styles to this file, and also import other style files */
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

table {
  width: 100%;
}

.mat-row:hover .mat-cell {
  background-color: rgba(255, 255, 255, 0.1);

  &.mat-column-id {
    cursor: pointer;
    text-decoration: underline;
  }
}

.space-top {
  margin-top: 40px;
}

[color=success] {
  color: greenyellow;
}

mat-form-field {
  display: block !important;
}
